.Event {
  text-align: left;
}

.Event .when {
  margin-bottom: 0.25rem;
}

.Event .what {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}